<template>

  <!-- <router-view></router-view> -->
 <div id="app">
      <!-- <d-header></d-header> -->
      <d-title ref="title"></d-title>
      <div class="container">
         <side-bar></side-bar>
         <div class="wrapper">
           <div class="wrapperView">
             <router-view  v-if="isRouterAlive"></router-view>
           </div>
         </div>
        <!-- <div class="record">鄂ICP备2021008245号-1</div> -->
        <!-- <div class="record">浙ICP备2022019108号</div> -->
      </div>
  </div>
</template>

<script>
import DHeader from '../components/d-header'
import DMenu from '../components/d-menu'
import SideBar from '../components/Sidebar'
import DTitle from '../components/d-title'
export default {
  name: 'home',
  data () {
    return {
      isRouterAlive:true
    }
  },
  provide () {
    return{
      reload:this.reload
    }
  },
  components: {
    DHeader,
    DMenu,
    DTitle,
    SideBar
  },
  computed: {
  },
  created () {
    console.log(process.env.NODE_ENV)
  },
  mounted () {
  },
  methods: {
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(()=>{
        this.isRouterAlive = true;
      })
    }
  },
  watch: {

  }
}
</script>

<style lang="scss">
  #app{
    width: 100%;
    height: 100%;
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    font-size: 14px;
    color: #606266;
    min-width: 1200px;
    .container{
      height: 100%;
    }
    .wrapper{
      padding: 70px 20px 20px 260px;
      width: 100%;
      height: 100%;
      background-color: #eee;
      box-sizing: border-box;
      .wrapperView{
        position: relative;
        min-width: 1100px;
        height: 100%;
        display: flex;
        flex:1;
        border-radius: 10px;
      }
      .wrapperView > div{
        flex: 1;
        flex-flow: column;
        display: flex;
        width: 100%;
      }
    }
    .record{
      width: calc(100% - 280px);
      position: fixed;
      bottom: 0px;
      text-align: right;
      height: 20px;
      line-height: 20px;
      color: #b9b8b8;
      margin-left: 260px;
    }
  }
</style>
