<template>
  <div class="template-parser">
    <!-- 一个模板文件，新增组件可以复制该文件 -->
  </div>
</template>

<script>
export default {
  name: "TemplateParser",
  props: {
    value: Object,
  },
};
</script>

<style lang="scss" scoped>
.template-parser {
}
</style>