<template>
  <el-menu
    :default-active="activeIndex"
    class="el-menu-vertical"
    @open="handleOpen"
    @close="handleClose"
    router
    :unique-opened="true"
  >
    <template v-for="menus in menuListTitle">
      <template v-if="menus.meta&&menus.meta.isShow">
        <el-submenu v-if="menus.children"
                    :index="menus.path"
                    :key="menus.path"
        >
          <template slot="title">
            <span>{{ menus.meta.title }}</span>
          </template>
          <template v-for="menu in menus.children">
            <el-menu-item
                v-if="menu.meta&&menu.meta.isShow"
                @click="select"
                :key="menu.path + ''"
                :index="menu.path"
            >{{ menu.meta.title }}</el-menu-item
            >
          </template>
        </el-submenu>
        <el-menu-item  :index="menus.path" :key="menus.path" v-else-if="!(/dynamicForm/.test(menus.path))">
          {{menus.meta.title}}
        </el-menu-item>
      </template>
    </template>
    <template v-if="/businessModule/.test($route.path)">
      <el-submenu
        v-for="menus in dynamicFormMenu"
        :key="menus.id"
        :index="menus.id + ''"
      >
        <template slot="title">
          <span class="title-text">{{ menus.groupName }}</span>
        </template>
        <el-menu-item
          v-for="menu in menus.basics"
          @click="select"
          :key="menu.id"
          :index="`/businessModule/dynamicForm/${menus.id}/${menu.id}/list`"
        >
          <span class="title-text">
            {{ menu.name }}
          </span>
        </el-menu-item>
      </el-submenu>
    </template>
  </el-menu>
</template>

<script>
import TemplateParser from "./dynamicForm/components/JSONParser/TemplateParser";

export default {
  name: "d-menu",
  components: {TemplateParser},
  data() {
    return {
      dynamicFormMenu: [],
      activeIndex:'',
      menuListTitle:[]
    };
  },
  inject: ["reload"],
  watch:{
    '$route':{
      immediate:true,
      handler(val,oldVal){
        this.activeIndex = this.changeRoute();
      }
    }
  },
  created() {
    this.getDynamicMenu();
  },
  methods: {
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    select() {
      this.reload();
    },
    getDynamicMenu() {
      this.$api.dynamicForm.externalAcl().then(({ data }) => {
        this.dynamicFormMenu = data;
        localStorage.setItem("dynamicFormMenu", JSON.stringify(data));
      });
    },
    changeRoute(){
      const {id} = this.$router.currentRoute.meta;
      const menuId = this.$router.currentRoute.matched[0].meta.id
      const menuList = JSON.parse(localStorage.getItem("menuList"));
      this.menuListTitle = menuList.find(e=>e.meta.id == menuId).children;
      if([201,208,214,6,221,396].includes(id)){
        return this.menuListTitle[0].children?
                    this.menuListTitle[0].children.filter(e=>e.meta&&e.meta.isShow)[0].path:
                        this.menuListTitle[0].path;
      }else{
        return this.matchedActiveIndex(this.$router.currentRoute)
      }
    },

    matchedActiveIndex(route){
      if(route.matched.length>3){
        return route.matched[2].path
      }else{
        return route.path
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-menu-vertical {
  position: fixed;
  z-index: 99;
  top: 80px;
  width: 240px;
  font-size: 16px;
  // height: 100%;
  height: calc(100% - 80px);
  background-color: #fff;
  overflow-y: auto;
  .title-text {
    width: 100%;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
